<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      flat
    >
      <v-container class="py-0 fill-height">
        <img
        height="40"
        src="@/assets/Marble-Official-Logo.png"
        alt="Marble">

        <v-btn
          v-for="link in links"
          :key="link"
          text
        >
          {{ link }}
        </v-btn>

        <v-spacer></v-spacer>        
      </v-container>
    </v-app-bar>

    <v-main class="lighten-3">
        <v-row>
          <v-col cols="2">
            <v-sheet>
              <v-list color="transparent">
                <v-list-item
                  v-for="n in stations"
                  :key="n.name"
                  link
                  :to="n.link"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ n.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>

          <v-col>
            <v-sheet
              min-height="70vh"
              rounded="lg"
            >
              <router-view></router-view>
            </v-sheet>
          </v-col>
        </v-row>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    data: () => ({
      stations: [
        {name: "Station 1", link: "/station/1",},
        {name: "Station 2", link: "/station/2",},
        {name: "Station 3", link: "/station/3",},
        {name: "Station 4", link: "/station/4",},
        {name: "Station 5", link: "/station/5",},
        {name: "Station 6", link: "/station/6",},
        {name: "Station 7", link: "/station/7",},
        {name: "Station 8", link: "/station/8",},
      ],
      links: [
        'Greater Omaha Stations',
      ],
    }),
  }
</script>