import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () =>
        import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    name: 'station', path: '/station/:id', meta: { requiresAuth: true }, component: () =>
      import(/* webpackChunkName: "login" */ '../components/Station'),
  },
]

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


/* eslint-disable no-unused-vars */
router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let user;
    Auth.currentAuthenticatedUser()
      .then((data) => {
        if (data && data.signInUserSession) {
          user = data;
        }
        next();
      })
      .catch((e) => {
        next({
          path: "/",
        });
      });
  }
  next();
});


export default router
